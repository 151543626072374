export default () => {
    if (process.client) {
      const script = document.createElement('script');
      script.type = 'module';
      script.crossOrigin;
      script.src = '/index.js';
      document.head.appendChild(script);

      const link = document.createElement('link');
      link.rel = 'stylesheet';
      link.href = '/index.css';
      document.head.appendChild(link);
    }
  };
