import { default as accountiPk3eaoYu7Meta } from "/opt/build/repo/pages/account.vue?macro=true";
import { default as _91id_9349q3hLlbnyMeta } from "/opt/build/repo/pages/calendars/[id].vue?macro=true";
import { default as index0pY4YBddLpMeta } from "/opt/build/repo/pages/calendars/index.vue?macro=true";
import { default as dashboardB0S6bZ6pqpMeta } from "/opt/build/repo/pages/dashboard.vue?macro=true";
import { default as indexlaFeZuYZkUMeta } from "/opt/build/repo/pages/index.vue?macro=true";
import { default as signinreYl1FuxfUMeta } from "/opt/build/repo/pages/signin.vue?macro=true";
import { default as signupQ6T3vUFXXDMeta } from "/opt/build/repo/pages/signup.vue?macro=true";
export default [
  {
    name: accountiPk3eaoYu7Meta?.name ?? "account",
    path: accountiPk3eaoYu7Meta?.path ?? "/account",
    meta: accountiPk3eaoYu7Meta || {},
    alias: accountiPk3eaoYu7Meta?.alias || [],
    redirect: accountiPk3eaoYu7Meta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/account.vue").then(m => m.default || m)
  },
  {
    name: _91id_9349q3hLlbnyMeta?.name ?? "calendars-id",
    path: _91id_9349q3hLlbnyMeta?.path ?? "/calendars/:id()",
    meta: _91id_9349q3hLlbnyMeta || {},
    alias: _91id_9349q3hLlbnyMeta?.alias || [],
    redirect: _91id_9349q3hLlbnyMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/calendars/[id].vue").then(m => m.default || m)
  },
  {
    name: index0pY4YBddLpMeta?.name ?? "calendars",
    path: index0pY4YBddLpMeta?.path ?? "/calendars",
    meta: index0pY4YBddLpMeta || {},
    alias: index0pY4YBddLpMeta?.alias || [],
    redirect: index0pY4YBddLpMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/calendars/index.vue").then(m => m.default || m)
  },
  {
    name: dashboardB0S6bZ6pqpMeta?.name ?? "dashboard",
    path: dashboardB0S6bZ6pqpMeta?.path ?? "/dashboard",
    meta: dashboardB0S6bZ6pqpMeta || {},
    alias: dashboardB0S6bZ6pqpMeta?.alias || [],
    redirect: dashboardB0S6bZ6pqpMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/dashboard.vue").then(m => m.default || m)
  },
  {
    name: indexlaFeZuYZkUMeta?.name ?? "index",
    path: indexlaFeZuYZkUMeta?.path ?? "/",
    meta: indexlaFeZuYZkUMeta || {},
    alias: indexlaFeZuYZkUMeta?.alias || [],
    redirect: indexlaFeZuYZkUMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/index.vue").then(m => m.default || m)
  },
  {
    name: signinreYl1FuxfUMeta?.name ?? "signin",
    path: signinreYl1FuxfUMeta?.path ?? "/signin",
    meta: signinreYl1FuxfUMeta || {},
    alias: signinreYl1FuxfUMeta?.alias || [],
    redirect: signinreYl1FuxfUMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/signin.vue").then(m => m.default || m)
  },
  {
    name: signupQ6T3vUFXXDMeta?.name ?? "signup",
    path: signupQ6T3vUFXXDMeta?.path ?? "/signup",
    meta: signupQ6T3vUFXXDMeta || {},
    alias: signupQ6T3vUFXXDMeta?.alias || [],
    redirect: signupQ6T3vUFXXDMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/signup.vue").then(m => m.default || m)
  }
]