import revive_payload_client_4sVQNw7RlN from "/opt/build/repo/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import components_plugin_KR1HBZs4kY from "/opt/build/repo/.nuxt/components.plugin.mjs";
import unhead_KgADcZ0jPj from "/opt/build/repo/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/opt/build/repo/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import prefetch_client_5tzzN0oIVL from "/opt/build/repo/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_OcT8LIvbNC from "/opt/build/repo/node_modules/nuxt-bugsnag/dist/runtime/plugin.mjs";
import supabase_client_I4Hv4qpJHd from "/opt/build/repo/node_modules/@nuxtjs/supabase/dist/runtime/plugins/supabase.client.mjs";
import chunk_reload_client_UciE0i6zes from "/opt/build/repo/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import calendar_script_zbgrkjBX5C from "/opt/build/repo/plugins/calendar-script.ts";
import i18n_VfGcjrvSkj from "/opt/build/repo/plugins/i18n.ts";
import showToast_client_i3Ts44oG79 from "/opt/build/repo/plugins/showToast.client.js";
export default [
  revive_payload_client_4sVQNw7RlN,
  components_plugin_KR1HBZs4kY,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  prefetch_client_5tzzN0oIVL,
  plugin_OcT8LIvbNC,
  supabase_client_I4Hv4qpJHd,
  chunk_reload_client_UciE0i6zes,
  calendar_script_zbgrkjBX5C,
  i18n_VfGcjrvSkj,
  showToast_client_i3Ts44oG79
]